<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      description: 'List of featured offers to lead user to specials',
      fields: {
        title: { type: 'text', label: 'Title' },
        description: { type: 'textarea', label: 'Description' },
        bigDescription: { type: 'checkbox', label: 'Big Description?' },
        categoryId: { type: 'select', label: 'Category', allowNull: false, options: {
          36:	'Couples Packages',
          37:	'Family Packages',
          38:	'Weekend Getaways',
          39:	'Event Packages',
          40:	'Limited Time Offers',
          41:	'Last Minute Getaways',
          42:	'Experience Packages',
          43:	'Casino Offers',
          44:	'Dining Packages',
          46:	'Black Friday',
          47:	'Towers',
          48:	'Offers to Display on Cove',
          49:	'Offers to display on Royal',
          50:	'Offers to display on Reef',
          51:	'Offers to display on Harborside',
          52:	'Offers to display on the Coral',
          53:	'Early Access',
          55:	'World Ocean Day',
          59:	'Travel Agent Clients',
          63:	'Summer in The Bahamas',
          78:	'Feature Sales (Cyber/January)',
        }, default: '36' },
        orderby: { type: 'select', label: 'Order By', options: { end_date: 'End Date', priority: 'Priority' } },
      },
      name: { label: 'Deals', group: 'Glue' },
      templates: [{ label: 'Deals', fields: { bigDescription: { value: true }, categoryId: { value: '54' }, description: { value: 'We\u2019ve put a few special offers together to make your stay with us even better.' }, orderby: { value: 'priority' }, title: { value: 'Make your trip extra special' } } }],
    })
  },
}
</script>

<template>
  <div><glue-specials-deals :devise="devise" mode="glue" /></div>
</template>
